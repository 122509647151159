'use client'

import React, { useEffect, useRef } from 'react';
import { FiCheck } from 'react-icons/fi'
import styles from './page.module.css'
import * as THREE from 'three';
import {createNoise3D} from 'simplex-noise';
import './index.css'

function HeliosAI() {
  return (
    <div className={styles.mainDiv} style={{ width:"100%", display:"flex", flexDirection:"column",  color:"#fff"}}>
        <style>
          { 
          `
            @import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap');

            body{
              background-color: #000000;
              margin: 0;
              padding: 0;
              font-family: 'Plus Jakarta Sans', sans-serif;
            }
          `
          }
        </style>
        <div style={{height:"calc(100vh - 100px)", display:"flex", justifyContent:"center", alignItems:"flex-start"}}>
          <Item1 tabNum={2} selected={0} buy={()=>{}} current={false}/>
        </div>
    </div>
  )
}

const Item1 = ()=>{

  const featuresList = {
      2: [
        <div key={1} style={{fontSize:"12px", display:"flex", alignItems:"center", justifyContent:"center", marginBottom:"5px", color:"rgba(255,255,255,0.5)", padding:"2.5px 5px 2px 5px", backgroundColor:"#212225", borderRadius:"3px", lineHeight:"18px"}}>Automatically included with paid plans</div>,
          <div key={2} style={{fontSize:"11.6px", display:"flex", alignItems:"center"}}><FiCheck color="#06BA63" size="13px" style={{marginRight:"10px"}} />AI-powered component generation</div>,
          <div key={3} style={{fontSize:"11.6px", display:"flex", alignItems:"center"}}><FiCheck color="#06BA63" size="13px" style={{marginRight:"10px"}} />AI auto code completion</div>,
          <div key={4} style={{fontSize:"11.6px", display:"flex", alignItems:"center"}}><FiCheck color="#06BA63" size="13px" style={{marginRight:"10px"}} />Smart collaborative development features</div>,
          <div key={5} style={{fontSize:"11.6px", display:"flex", alignItems:"center"}}><FiCheck color="#06BA63" size="13px" style={{marginRight:"10px"}} />AI generated component documentation</div>,
          <div key={6} style={{fontSize:"11.6px", display:"flex", alignItems:"center"}}><FiCheck color="#06BA63" size="13px" style={{marginRight:"10px"}} />Group recommendations for properties</div>,
          <div key={7} style={{fontSize:"11.6px", display:"flex", alignItems:"center"}}><FiCheck color="#06BA63" size="13px" style={{marginRight:"10px"}} />Priority AI Chat Support</div>,
          ]
  }

  return <div className={styles.tierDiv} style={{backgroundColor:"#191a1d", minWidth:"300px", width:"300px", margin:"5px", display:"flex", flexDirection:"column",  borderRadius: "15px", justifyContent:"space-between", boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", marginTop:"70px"}}>
      
    <div style={{display:"flex", flexDirection:"column"}}>
        <div style={{display:"flex", justifyContent:"space-between", margin:"20px 20px 0px 20px", alignItems:"center"}}>
            <div className={styles.tierName} style={{ fontSize:"16px", color:"rgba(255,255,255,0.7)"}}>helios<span style={{color:"#06ba63"}}>AI</span></div>
            <div style={{fontSize:"7px", padding:"0 6px", height:"20px", border:"solid 1px rgba(255,255,255,0.1)", borderRadius:"30px", display:"flex", justifyContent:"center", alignItems:"center", color:"rgba(255,255,255,0.7)"}}>COMING SOON</div>
        </div>
        <Blob width={300} height={200} />
        
        <div style={{display:"flex", margin:"0px 20px", justifyContent:"center", flexDirection:"column", lineHeight:"26px", color:"rgba(255,255,255,0.65)", marginTop:"5px"}}>
            {featuresList[2].map((item, index)=>{return item})}
        </div>
    </div>
    <div style={{display:"flex", margin:"15px 20px 20px 20px", alignItems:"center"}}>
        <button onClick={()=>{}} className={styles.actionBtn} style={{flex:"1", padding:"12px 12px", borderRadius:"6px", fontSize:"14px", cursor:"pointer", backgroundColor: "#00A376", color: "#fff"}}>Join the waitlist</button>
    </div>
    </div>
}

const Blob = ({ width, height }) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const renderer = new THREE.WebGLRenderer({
      canvas: canvas,
      context: canvas.getContext('webgl2'),
      antialias: true,
      alpha: true,
    });
    const noise3D = createNoise3D(Math.random);

    renderer.setSize(width, height);
    renderer.setPixelRatio(window.devicePixelRatio || 1);

    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(35, width / height, 0.1, 1000);
    camera.position.z = 5;

    const geometry = new THREE.SphereGeometry(0.8, 128, 128);
    const material = new THREE.MeshPhongMaterial({
      color: 0xE4ECFA,
      shininess: 250,
      specular: 0x111111,
    });

    const lightTop = new THREE.DirectionalLight(0xFFFFFF, 1);
    lightTop.position.set(0, 500, 200);
    lightTop.castShadow = true;
    scene.add(lightTop);

    const lightBottom = new THREE.DirectionalLight(0xFFFFFF, 0.45);
    lightBottom.position.set(0, -500, 400);
    lightBottom.castShadow = true;
    scene.add(lightBottom);

    const ambientLight = new THREE.AmbientLight(0x798296);
    scene.add(ambientLight);

    const sphere = new THREE.Mesh(geometry, material);
    scene.add(sphere);

    const update = () => {
      const time = performance.now() * 0.00001 * 20 * 1;
      const spikes = 0.8;

      const position = sphere.geometry.attributes.position;
      const count = position.count;

      for (let i = 0; i < count; i++) {
        const p = new THREE.Vector3().fromBufferAttribute(position, i);
        p.normalize().multiplyScalar(
          1 + 0.3 * noise3D(p.x * spikes, p.y * spikes, p.z * spikes + time)
        );
        position.setXYZ(i, p.x, p.y, p.z);
      }

      position.needsUpdate = true;
      sphere.geometry.computeVertexNormals();
    };

    const animate = () => {
      update();
      renderer.render(scene, camera);
      requestAnimationFrame(animate);
    };

    requestAnimationFrame(animate);

    return () => {
      renderer.dispose();
    };
  }, [width, height]);

  return (
    <div
      id="blob"
      style={{ width, height, overflow: 'hidden', padding: 0, margin: 0 }}
    >
      <canvas
        ref={canvasRef}
        style={{ display: 'block', width: '100%', height: '100%' }}
      />
    </div>
  );
};

export default HeliosAI